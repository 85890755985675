@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

body {
  font-family: "Nunito", sans-serif;
}

.anchor {
  margin-top: -102px;
}

/* Animation on the social icons */
.container-animation{
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  background: rgba(241,241,241,1);
}
.container-animation > .circle-animation {    
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  transform: scale(0);
  transition: all 450ms ease 0ms;
}
.container-animation:hover > .circle-animation {
  background: rgb(251,193,25);
  background: linear-gradient(153deg, rgba(251,193,25,1) 0%, rgba(202,80,150,1) 33%, rgba(120,95,164,1) 66%, rgba(8,141,205,1) 100%);
  border-radius: 50%;
  transform: scale(1);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
